
<template>
  <div class="login_boxd" @keyup.enter="submitForm('userForm')">
    <!-- 登录表单区域 -->
    <el-form
      size="mini"
      :model="userForm"
      :rules="rules"
      ref="userForm"
    >
      <h2 style="margin-bottom: 20px; margin-top: 20px; text-align: left;line-height: normal;">
        修改密码
      </h2>
      <!-- 用户名 -->
      <el-form-item prop="loginname">
        <el-input
         
          placeholder="用户名"
          v-model="userForm.loginname"
          prefix-icon="el-icon-user"
        ></el-input>
      </el-form-item>
      <!-- 旧密码 -->
      <el-form-item prop="oldpassword">
        <el-input
         
          placeholder="旧密码"
          show-password
          v-model="userForm.oldpassword"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <!-- 新密码 -->
      <el-form-item prop="newpassword">
        <el-input
         
          placeholder="新密码"
          show-password
          v-model="userForm.newpassword"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <!-- 新密码 -->
      <el-form-item prop="renewpassword">
        <el-input
         
          placeholder="确认密码"
          show-password
          v-model="userForm.renewpassword"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <el-form-item> </el-form-item>
      <!-- 修改密码按钮 -->
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('userForm')"
          :loading="loading"
          >修改密码</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { UpdatePwd } from "@/network/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/); //6-10位，英文字母+数字，长度为6-10个字符
        let result = reg.test(this.userForm.newpassword);
        if (!result) {
          callback(new Error("英文字母+数字，长度为6-10个字符"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.userForm.newpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      codeimg: "",
      formLabelWidth: "90px",
      userForm: {
        loginname: "",
        oldpassword: "",
        newpassword: "",
        renewpassword: "",
      },
      rules: {
        loginname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        oldpassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newpassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        renewpassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.userForm.loginname = this.$store.state.login.user;
    this.userForm.oldpassword = this.$store.state.login.pwd;
  },
  methods: {
    submitForm(formName) {
      this.loading = true;
      //   this.dialogFormVisible = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   alert("submit!");
          UpdatePwd(this.userForm).then((res) => {
            console.log(res);
            if (res.code == -1) {
              this.$message({
                message: "修改失败",
                center: true,
                type: "error",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/ResetPwd");
                  this.loading = false;
                },
              });
            } else if (res.code == 1) {
              //成功返回登录页
              this.loading = false;
              this.$message({
                message: "修改成功",
                center: true,
                type: "success",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/Login1");
                  this.loading = false;
                },
              });
            } else if (res.code == 102) {
              //成功返回登录页
              this.$message({
                message: res.msg,
                center: true,
                type: "success",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/Login1" + window.location.search);
                  this.loading = false;
                },
              });
            } else {
              this.$message({
                message: res.msg,
                offset: "40%",
                center: true,
                type: "error",
                duration: 1500,
                onClose: () => {
                  this.userForm = {
                    loginname: "",
                    oldpassword: "",
                    newpassword: "",
                    renewpassword: "",
                  };
                  this.loading = false;
                },
              });
            }
          });
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.login_boxd{
  width: 100%;
  height: 100%;
  /* width: 290px;
  height: 350px; */
  // background-color: #fff;
  /* background-color: #26447a; */
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
    background: rgba(255, 255, 255, 0.8);
}
.el-form {
  padding: 20px 30px;
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.el-button {
  width: 100%;
  height: 45px;
  font-size: 18px;
  line-height: 30px;
}
.code {
  width: 50%;
  float: left;
}
img {
  /* style="width: 100px; height: 30px; margin-left:5px;vertical-align: middle;" */
  display: line-inline;
  width: 45%;
  height: 28px;
  margin-left: 10px;
  vertical-align: middle;
  border-radius: 3px;
}
.rememberMe {
  color: #fff;
}
/deep/.el-input--mini .el-input__inner {
  height: 40px;
  font-size: 15px;
  line-height: 40px;
}
</style>